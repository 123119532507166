import { RequestTypes } from '../hooks/useRequest'

export const GREEN_TOOLS_API_PREFIX = '/api/green-tools/v1/'
export const GREEN_TOOLS_CREDENTIALS = 'contracts/set-group-consumers'
export const GREEN_TOOLS_GROUPS_FILE = 'contracts/get-consumers-from-groups-file'

export const sendGroupRealization = {
    link: `${GREEN_TOOLS_API_PREFIX}${GREEN_TOOLS_CREDENTIALS}`,
    method: RequestTypes.post,
}

export const getFromFile = {
    link: `${GREEN_TOOLS_API_PREFIX}${GREEN_TOOLS_GROUPS_FILE}`,
    method: RequestTypes.post,
}
