import { FC, useMemo, useEffect } from 'react'
import { Import } from '../../../../../../assets/icons'
import { Input, Button } from 'antd'
import moment, { Moment } from 'moment'
import {
    CenteredSpin,
    Box,
    DropdownSelector2,
    ImportButton,
    TimeRangePicker,
} from '../../../../../ui'
import { useAppSelector } from '../../../../../../store/hooks'
import {
    selectDictionariesMaps,
    selectDictionariesOptions,
} from '../../../../../../store/dictionaries'
import { getCertificates, getRedeemedToMe, getGenerators, getGeneratorsForAccount } from '../../../../config'
import useMount from '../../../../../hooks/useMount'
import { monthPicker, statusIdPicker } from '../../../../../helpers'
import useRequest from '../../../../../hooks/useRequest'
import MoreFilters from '../MoreFilters/MoreFilters'
import style from './style.m.less'
import { getStatus } from '../../../../../UserCertificate/UserCertificate'
import InputOnlyNumber from '../../../../../ui/InputOnlyNumber/InputOnlyNumber'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

interface IControlsBlock {
    toolType: string
    onChange: (value: string) => void
    onChangeFilters: (data: string | number, key: string, obj?: any) => void
    currentOption: string
    isUserType?: boolean
    total?: number
    options: { label: string; id: string }[]
    isDisabled?: boolean
    fetchAccounts: any
    accountsResult: any
    isLoadingAccounts: boolean
    filters: any
    isThirdPart?: boolean
    isGOOwner?: boolean
    isClientUser?: boolean
    isTSOGPUser?: boolean
}

const ControlsBlock: FC<IControlsBlock> = ({
    toolType,
    options,
    currentOption,
    total,
    onChange,
    filters,
    onChangeFilters,
    fetchAccounts,
    accountsResult,
    isLoadingAccounts,
    isThirdPart,
    isGOOwner,
    isClientUser,
    isTSOGPUser
}) => {
    const { renewableEnergyTypesOptions } = useAppSelector(selectDictionariesOptions) ?? {}
    const { fetch: fetchGenerators, result: generatorsResult } = useRequest(
        isThirdPart ? getGenerators : getGeneratorsForAccount,
    )
    const navigate = useNavigate()
    const { renewableEnergyTypesMap } = useAppSelector(selectDictionariesMaps) ?? {}

    const isCertificate = toolType === 'Сертификаты происхождения'
    const isContract = toolType === 'Зеленые договоры'
    const {t} = useTranslation()

    const realRenewableEnergyTypesOptions = useMemo(
        () =>
            renewableEnergyTypesOptions
                ? [
                      { id: 0, value: null, text: 'Все', label: 'Все' },
                      ...renewableEnergyTypesOptions,
                  ]
                : [{ id: 0, value: null, text: 'Все', label: 'Все' }],
        [renewableEnergyTypesOptions],
    )

    const generatorsOptions = generatorsResult?.generators
        ? [
              {
                  name: 'Все',
                  value: 'Все',
                  id: '',
              },
              ...generatorsResult?.generators,
          ]
        : [
              {
                  name: 'Все',
                  value: 'Все',
                  id: '',
              },
          ]

    useMount(() => {
        fetchAccounts({ errorCodeMessage: true })
    })

    useEffect(() => {
        if (accountsResult?.accounts?.length) {
            fetchGenerators({ addToLink: accountsResult?.accounts[0].id, errorCodeMessage: true })
            onChangeFilters('', '', {
                account_id: accountsResult?.accounts[0].id,
                status_id: statusIdPicker(currentOption),
            })
        }
    }, [accountsResult, isThirdPart])

    const updateFilters = (data: any, key: string) => {
        if (key === 'account_id') {
            fetchGenerators({ addToLink: data, errorCodeMessage: true })
            return onChangeFilters('', '', {
                account_id: data,
                generator_id: '',
            })
        }
        switch (key) {
            case 'rangeDate':
                return onChangeFilters('', '', {
                    ts_created_from: data ? moment(data[0]).startOf('day') : undefined,
                    ts_created_to: data ? moment(data[1]).endOf('day') : undefined,
                })
            case 'date':
                return onChangeFilters('', '', {
                    month: data?.month,
                    year: data?.year,
                })
            default:
                return onChangeFilters(data, key)
        }
    }

    const { fetch: fetchCertiticates } = useRequest(getCertificates)
    const { fetch: fetchRedeemedToMe } = useRequest(getRedeemedToMe)

    const onRedeemedToMeSuccess = (data: any) => {
        const certificates = data?.certificates

        if (!certificates || !certificates?.length) {
            return
        }

        const rows = [
            [
                'Номер СП',
                'Статус сертификата',
                'Тип ВИЭ',
                'Объем сертификата',
                'Погашено в меня',                
                'Название ГО на котором создан СП',
                'Период производства',
                'Дата выдачи',
                'Срок действия',
            ],
        ]
        certificates.map(
            ({
                certificate_number,
                status_id,
                renewable_energy_type_id,
                volume,
                consumption_volume,
                generator_name,
                ts_created,
                ts_expired,
                year,
                month,
            }) => {
                rows.push([
                    certificate_number,
                    getStatus(status_id),
                    renewableEnergyTypesMap?.get(renewable_energy_type_id),
                    volume,
                    consumption_volume,
                    generator_name,
                    `${monthPicker(month)} ${year}`,
                    moment(ts_created).format('DD.MM.YYYY'),
                    moment(ts_expired).format('DD.MM.YYYY'),
                ])
            },
        )

        const csvContent =
            'data:text/csv;charset=utf-8,\uFEFF' + rows.map((e) => e.join(';')).join('\n')

        const encodedUri = encodeURI(csvContent)
        window.open(encodedUri)

    }

    const onCertiticatesSuccess = (data: any) => {
        const certificates = data?.certificates

        if (!certificates || !certificates?.length) {
            return
        }

        const rows = [
            [
                'Номер СП',
                'Статус сертификата',
                'Тип ВИЭ',
                'Объем сертификата',
                'Название ГО на котором создан СП',
                'Период производства',
                'Дата выдачи',
                'Срок действия',
            ],
        ]
        certificates.map(
            ({
                certificate_number,
                status_id,
                renewable_energy_type_id,
                volume,
                generator_name,
                ts_created,
                ts_expired,
                year,
                month,
            }) => {
                rows.push([
                    certificate_number,
                    getStatus(status_id),
                    renewableEnergyTypesMap?.get(renewable_energy_type_id),
                    volume,
                    generator_name,
                    `${monthPicker(month)} ${year}`,
                    moment(ts_created).format('DD.MM.YYYY'),
                    moment(ts_expired).format('DD.MM.YYYY'),
                ])
            },
        )

        const csvContent =
            'data:text/csv;charset=utf-8,\uFEFF' + rows.map((e) => e.join(';')).join('\n')

        const encodedUri = encodeURI(csvContent)
        window.open(encodedUri)

    }

    const onImportDataToCSVSuccess = (data: any) => {
        if( currentOption === 'Погашенные в меня' ) {
            onRedeemedToMeSuccess(data)
        } else {
            onCertiticatesSuccess(data)
        }

    }    

    const importDataToCSV = () => {
/*        
        if( currentOption === 'Погашенные в меня' ) {
            fetchRedeemedToMe({
                body: {
                    filters,
                },
                onSuccess: (data) => onRedeemedToMeSuccess(data)
            })
        } else {
            fetchCertiticates({
                body: {
                    filters,
                },
                onSuccess: (data) => onCertiticatesSuccess(data)
            })
        }
*/            
         fetchCertiticates({
            body: {
                filters,
            },
            onSuccess: (data) => onImportDataToCSVSuccess(data)
        })
    }

    const groupPaymentRedirect = () => {
        let period = new Date();
        period.setMonth(period.getMonth() - 1)
        period.setDate(1)
        const record = {period: period, accountId: accountsResult?.accounts[0].id}
        navigate('/user-my-green-tools/group-payment', {
            state: { record },
        })
    }

    if (
        isLoadingAccounts ||
        accountsResult === undefined ||
        // isLoadingGenerators ||
        generatorsResult === undefined
    ) {
        return <CenteredSpin />
    }

    const onTimeRangePickerChange =
        ([from, to]: string[]) =>
        (dates: [Moment, Moment] | null) => {
            const isArray = Array.isArray(dates)
            onChangeFilters('', '', {
                [from]: isArray ? dates[0] : dates,
                [to]: isArray ? dates[1] : dates,
            })
        }

    return (
        <div className={style.controlsBlock}>
            <div className={style.inputsRow}>
                <div>
                    <div>Лицевой счет</div>
                    <div className={style.inputBlock}>
                        <DropdownSelector2
                            customStyle={{ width: '296px' }}
                            options={accountsResult?.accounts?.map((item: any) => {
                                return {
                                    text: `${item.name} (№${item.number})`,
                                    value: item.id,
                                    id: item.id,
                                    key: item.id,
                                }
                            })}
                            onChange={(value) => updateFilters(value, 'account_id')}
                        />
                    </div>
                </div>
                <div className={style.leftGapBlock}>
                    <div>
                        Номер {isCertificate ? 'сертификата' : isContract ? 'ЗД' : 'инструмента'}
                    </div>
                    <div className={style.inputBlock}>
                        <Input
                            className={style.certificateNumberInput}
                            placeholder={`Введите номер ${
                                isCertificate ? 'СП' : isContract ? 'договора' : 'инструмента'
                            }`}
                            onChange={(event) =>
                                updateFilters(
                                    event.target.value,
                                    isCertificate
                                        ? 'certificate_number'
                                        : isContract
                                        ? 'contract_number'
                                        : 'ext_instrument_number',
                                )
                            }
                            key={
                                isCertificate
                                    ? '232bc422-8cd5-4ccb-90e3-da00e5987634'
                                    : isContract
                                    ? 'd2549f08-1b08-45eb-8d93-f2f76df772fc'
                                    : 'aa89baf6-8103-4f1b-bb14-6619ad7c7ba5'
                            }
                        />
                    </div>
                </div>
                {isContract ? (
                    <>
                        <div className={style.leftGapBlock}>
                            <div>Вид ВИЭ</div>
                            <div className={style.inputBlock}>
                                <DropdownSelector2
                                    customStyle={{ width: '296px' }}
                                    options={realRenewableEnergyTypesOptions}
                                    onChange={(value) =>
                                        updateFilters(value, 'renewable_energy_type_id')
                                    }
                                    key={'bef9c80d-5e37-4991-aaf5-39e3507ab081'}
                                />
                            </div>
                        </div>
                        <div className={style.leftGapBlock}>
                            <div>Дата регистрации</div>
                            <div className={style.inputBlock}>
                                <TimeRangePicker
                                    style={{ width: '296px' }}
                                    onChange={onTimeRangePickerChange([
                                        'ts_registration_from',
                                        'ts_registration_to',
                                    ])}
                                    key={'dc49b82c-8b2c-43b4-88b8-12159aaa991b'}
                                />
                            </div>
                        </div>
                    </>
                ) : isCertificate ? (
                    <>
                        <div className={style.leftGapBlock}>
                            <div>Номинал сертификата, кВт*ч</div>
                            <Box direction="row" gap="8px">
                                <Input
                                    className={style.certificateNumberInputShort}
                                    placeholder="С"
                                    type="number"
                                    value={filters.volume_from || null}
                                    onChange={(event) =>
                                        updateFilters(
                                            Number(event.target.value.replace('-', '')),
                                            'volume_from',
                                        )
                                    }
                                    key={'ac7e9858-1ae8-4e3e-9695-4c3e7119c984'}
                                />
                                <Input
                                    className={style.certificateNumberInputShort}
                                    placeholder="По"
                                    type="number"
                                    value={filters.volume_to || null}
                                    min={0}
                                    onChange={(event) =>
                                        updateFilters(
                                            Number(event.target.value.replace('-', '')),
                                            'volume_to',
                                        )
                                    }
                                    key={'dd65c7ac-7a47-4394-b206-5f0761acad17'}
                                />
                            </Box>
                        </div>
                        <div className={style.leftGapBlock}>
                            <div>Вид ВИЭ</div>
                            <div className={style.inputBlock}>
                                <DropdownSelector2
                                    customStyle={{ width: '296px' }}
                                    options={realRenewableEnergyTypesOptions}
                                    onChange={(value) =>
                                        updateFilters(value, 'renewable_energy_type_id')
                                    }
                                    key={'2acd79a9-65e8-47fc-bed8-1681717a7c65'}
                                />
                            </div>
                        </div>
                    </>
                ) : (
                    <>
                        <div className={style.leftGapBlock}>
                            <div>Вид ВИЭ</div>
                            <div className={style.inputBlock}>
                                <DropdownSelector2
                                    customStyle={{ width: '296px' }}
                                    options={realRenewableEnergyTypesOptions}
                                    onChange={(value) =>
                                        updateFilters(value, 'renewable_energy_type_id')
                                    }
                                    key={'821a8a9d-f8e7-4f62-bc2c-46ff239148de'}
                                />
                            </div>
                        </div>
                        <div className={style.leftGapBlock}>
                            <div className={style.label}>ЗГО</div>
                            <DropdownSelector2
                                customStyle={{ width: '296px' }}
                                value={filters.generator_id}
                                options={generatorsOptions?.map((item: any) => {
                                    return {
                                        text: item.name,
                                        value: item.id,
                                        id: item.id,
                                    }
                                })}
                                placeholder="Выберите ЗГО"
                                onChange={(value) => updateFilters(value, 'generator_id')}
                                key={'a97dd623-162e-4000-b221-0655518631e7'}
                            />
                        </div>
                    </>
                )}
            </div>
            <MoreFilters
                toolType={toolType}
                filters={filters}
                updateFilters={updateFilters}
                generators={generatorsResult?.generators}
                onTimeRangePickerChange={onTimeRangePickerChange}
            />
            <div className={style.selectorWrapper}>
                <div className={style.selector}>
                    <div className={style.flexContainer}>
                        {options?.map((item) => {
                            const { label, id } = item
                            return (
                                <>
                                    <div
                                        className={
                                            currentOption === label
                                                ? style.greenToolSelected
                                                : style.greenTool
                                        }
                                        key={id}
                                        onClick={() => onChange(label)}
                                    >
                                        {label}
                                    </div>
                                    <div className={style.spaceBar}></div>
                                </>
                            )
                        })}
                        <div className={style.total}>{`Всего: ${total}`}</div>
                        {isCertificate && (
                            <ImportButton
                                onClick={importDataToCSV}
                                value={'Выгрузить данные'}
                                icon={<Import />}
                                customIconStyle={{ marginLeft: '56px' }}
                            />
                        )}
                    </div>
                </div>
                {(isContract && isGOOwner) && (
                    <Button onClick={groupPaymentRedirect} className={style.paymentButton}>
                        {t('greenTools.payAllContracts')}
                    </Button>
                )}
                {(isContract && (isClientUser || isTSOGPUser)) && (
                    <Button
                        onClick={() => navigate(
                            '/group-realization-ag', {
                                state: {accountId: accountsResult?.accounts[0].id},
                            }
                        )}
                        className={style.paymentButton}
                    >
                        {t('greenTools.specifyAllConsumers')}
                    </Button>
                )}
            </div>
        </div>
    )
}

export default ControlsBlock
