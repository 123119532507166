import { RequestTypes } from '../hooks/useRequest'

export const GREEN_TOOLS_API_PREFIX = '/api/green-tools/v1/'
export const GREEN_TOOLS_ACCOUNTS = 'accounts?need_opened_only=1'

export const getAccounts = {
    link: `${GREEN_TOOLS_API_PREFIX}${GREEN_TOOLS_ACCOUNTS}`,
    method: RequestTypes.get,
}

export const GENERATION_ATTRIBUTES_GAS_API_PREFIX = '/api/generation_attributes/v1/gas/'
export const GENERATION_ATTRIBUTES_API_PREFIX = '/api/generation_attributes/v1/gas/actions/'
export const GENERATION_ATTRIBUTES_LIST = 'ga/list'
export const GENERATION_REDEMPTION = 'own_redemption'

export const getGeneratorsPeriods = {
    link: `${GENERATION_ATTRIBUTES_GAS_API_PREFIX}${GENERATION_ATTRIBUTES_LIST}`,
    method: RequestTypes.get,
}

export const postRedemption = {
    link: `${GENERATION_ATTRIBUTES_API_PREFIX}${GENERATION_REDEMPTION}`,
    method: RequestTypes.post,
}
