// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".modal_JcBAT {\n  width: 600px;\n}\n.modalWrapper_f9YMj {\n  margin-bottom: 16px;\n}\n.header_DU2L3 {\n  font-weight: 600;\n  font-size: 16px;\n  line-height: 24px;\n}\n.text_NPNLP {\n  width: 360px;\n}\n.buttonsBlock_DYg29 {\n  display: flex;\n  justify-content: flex-end;\n  margin-top: 24px;\n}\n.payAlert_D8ite {\n  display: flex;\n  flex-direction: column;\n  gap: 8px;\n  padding: 12px 24px;\n  margin-top: 19px;\n  background: #fafafa;\n  border: 1px solid #f0f0f0;\n  border-radius: 16px;\n}\n.payHeader_CZom9 {\n  font-weight: 600;\n}\n.payText_xUQCN {\n  font-size: 12px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/DetailedCard/components/ProlongPaymentModal/style.m.less"],"names":[],"mappings":"AAAA;EACI,YAAA;AACJ;AAEA;EACI,mBAAA;AAAJ;AAGA;EACI,gBAAA;EACA,eAAA;EACA,iBAAA;AADJ;AAIA;EACI,YAAA;AAFJ;AAKA;EACI,aAAA;EACA,yBAAA;EACA,gBAAA;AAHJ;AAMA;EACI,aAAA;EACA,sBAAA;EACA,QAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;EACA,yBAAA;EACA,mBAAA;AAJJ;AAOA;EACI,gBAAA;AALJ;AAQA;EACI,eAAA;AANJ","sourcesContent":[".modal {\n    width: 600px;\n}\n\n.modalWrapper {\n    margin-bottom: 16px;\n}\n\n.header {\n    font-weight: 600;\n    font-size: 16px;\n    line-height: 24px;\n}\n\n.text {\n    width: 360px;\n}\n\n.buttonsBlock {\n    display: flex;\n    justify-content: flex-end;\n    margin-top: 24px;\n}\n\n.payAlert {\n    display: flex;\n    flex-direction: column;\n    gap: 8px;\n    padding: 12px 24px;\n    margin-top: 19px;\n    background: #fafafa;\n    border: 1px solid #f0f0f0;\n    border-radius: 16px;\n}\n\n.payHeader {\n    font-weight: 600;\n}\n\n.payText {\n    font-size: 12px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal": "modal_JcBAT",
	"modalWrapper": "modalWrapper_f9YMj",
	"header": "header_DU2L3",
	"text": "text_NPNLP",
	"buttonsBlock": "buttonsBlock_DYg29",
	"payAlert": "payAlert_D8ite",
	"payHeader": "payHeader_CZom9",
	"payText": "payText_xUQCN"
};
export default ___CSS_LOADER_EXPORT___;
