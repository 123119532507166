import { RequestTypes } from '../hooks/useRequest'

export const GREEN_TOOLS_API_PREFIX = '/api/green-tools/v1/'
export const GENERATORS_API_PREFIX = '/api/generators/v1/'
export const GENERATORS_USER = 'gos-ga'
// export const GENERATORS_PERIODS = 'go-periods?generator_id='
export const GREEN_TOOLS_ACCOUNTS = 'accounts?need_opened_only=1'
export const GREEN_TOOLS_ISSUE = 'certificate'

export const getUserGenerators = {
    link: `${GENERATORS_API_PREFIX}${GENERATORS_USER}`,
    method: RequestTypes.get,
}

// export const getGeneratorsPeriods = {
//     link: `${GENERATORS_API_PREFIX}${GENERATORS_PERIODS}`,
//     method: RequestTypes.get,
// }

export const getAccounts = {
    link: `${GREEN_TOOLS_API_PREFIX}${GREEN_TOOLS_ACCOUNTS}`,
    method: RequestTypes.get,
}

export const issueCertificate = {
    link: `${GREEN_TOOLS_API_PREFIX}${GREEN_TOOLS_ISSUE}`,
    method: RequestTypes.post,
}

// новое апи
export const GENERATION_ATTRIBUTES_API_PREFIX = '/api/generation_attributes/v1/gas/'
export const GENERATION_ATTRIBUTES_TOTAL = 'ga/total'
export const GENERATION_ATTRIBUTES_GOS = 'ga/gos'
export const GENERATORS_PERIODS = 'ga/gos?generator_id='
export const GENERATION_ATTRIBUTES_LIST = 'ga/list'

export const getTotalGA = {
    link: `${GENERATION_ATTRIBUTES_API_PREFIX}${GENERATION_ATTRIBUTES_TOTAL}`,
    method: RequestTypes.get,
}
export const getListGO = {
    link: `${GENERATION_ATTRIBUTES_API_PREFIX}${GENERATION_ATTRIBUTES_GOS}`,
    method: RequestTypes.get,
}

export const getGeneratorsPeriods = {
    link: `${GENERATION_ATTRIBUTES_API_PREFIX}${GENERATION_ATTRIBUTES_LIST}`,
    method: RequestTypes.get,
}
