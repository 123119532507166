import { FC, useEffect, useState } from 'react'
import { Card, DropdownSelector2, LoadingContainer } from '../ui'
import { Divider, Input, Form, InputNumber, message } from 'antd'
import { useTranslation } from 'react-i18next'
import { useNavigate, useLocation } from 'react-router-dom'
import { ArrowLeft } from '../../assets/icons'
import useRequest from '../hooks/useRequest'
import { ButtonsBlock } from '../Edit/components'
import { getGeneratorsPeriods, getAccounts, postRedemption } from './config'
import moment from 'moment'
import { useGetTariffRate } from '../hooks'
import { currencyFormat } from '../helpers'
import capitalize from 'lodash/capitalize'
import useMount from '../hooks/useMount'
import style from './style.m.less'

const OwnRepaymentGA: FC = () => {
    const { t } = useTranslation()

    const [form] = Form.useForm()
    const navigate = useNavigate()
    const location = useLocation()
    const { generatorId }: any = location?.state || {}

    const [currentPeriod, setCurrentPeriod] = useState<string | null>(null)
    const [currentPeriodVolume, setCurrentPeriodVolume] = useState<number>()
    const [currentAccount, setCurrentAccount] = useState(null)
    const [volume, setVolume] = useState()

    const {
        fetch: fetchPeriods,
        result: generatorsPeriods,
        isLoading: isLoadingPeriods,
    } = useRequest(getGeneratorsPeriods)
    const {
        fetch: fetchAccounts,
        result: accounts,
        isLoading: isLoadingAccounts,
    } = useRequest(getAccounts)
    const { fetch: sendRedemption } = useRequest(postRedemption)

    const { rate } = useGetTariffRate({
        id: 66,
        volume,
        dependency: volume,
    })

    const goBack = () => {
        navigate(`/generator-card/${generatorId}`)
    }

    const onSubmit = () => {
        form.submit()
    }

    useMount(() => {
        fetchPeriods({
            getParams: {
                generator_id: generatorId,
                limit_all: true
            },
            errorCodeMessage: true,
        })
        fetchAccounts({
            errorCodeMessage: true,
        })
    })

    useEffect(() => {
        if (!generatorsPeriods) {
            return
        }
        const firstPeriod = generatorsPeriods?.list?.[0]
        if (firstPeriod) {
            setCurrentPeriod(firstPeriod.period)
            setCurrentPeriodVolume(firstPeriod.volume)
        }
    }, [generatorsPeriods])

    useEffect(() => {
        const found = accounts?.accounts.find((item) => item.is_main)
        setCurrentAccount(found)
    }, [accounts])

    const onFinish = async () => {
        const body = {
            generator_id: generatorId,
            period: currentPeriod,
            volume,
            account_id: currentAccount.id,
        }
        sendRedemption({
            body,
            successMessage: {
                title: 'Успешно',
                description: 'Собственное погашение атрибутов генерации реализовано',
            },
            onSuccess: () => navigate(`/generator-card/${generatorId}`),
            errorCodeMessage: true,
        })
    }

    return (
        <LoadingContainer isLoading={isLoadingPeriods || isLoadingAccounts}>
            <div className={style.container}>
                <div className={style.titleWrapper}>
                    <span className={style.backLink} onClick={() => navigate(-1)}>
                        <ArrowLeft />
                    </span>
                    <div className={style.mainHeader}>
                        Собственное погашение атрибутов генерации
                    </div>
                </div>

                <Card className={style.repaymentCard}>
                    <div className={style.title}>Выберите период и объем погашения</div>
                    <Form form={form} onFinish={onFinish} name="ownRepayment" layout="vertical">
                        <Form.Item name="period" label="Период">
                            <DropdownSelector2
                                placeholder="Введите значение"
                                customStyle={{ width: '400px' }}
                                options={(generatorsPeriods?.list || []).map(
                                    (item: any, index: number) => {
                                        const dataMoment = moment(item.period, 'YYYY-MM-DD')
                                        return {
                                            text: `${capitalize(
                                                dataMoment.format('MMMM, YYYY'),
                                            )} (${currencyFormat(item.volume)} кВт*ч)`,
                                            value: index,
                                            id: index,
                                        }
                                    },
                                )}
                                onChange={(idx) => {
                                    const selectedPeriod = generatorsPeriods?.list[idx]
                                    setCurrentPeriod(selectedPeriod.period)
                                    setCurrentPeriodVolume(selectedPeriod.volume)
                                    form.validateFields(['volume'])
                                }}
                                value={currentPeriod}
                            />
                        </Form.Item>
                        <Form.Item
                            name="volume"
                            className={style.dropdownWrapper}
                            validateFirst={true}
                            rules={[
                                () => ({
                                    validator(_, value) {
                                        if (value > currentPeriodVolume) {
                                            return Promise.reject(
                                                new Error(
                                                    `Доступный объем: ${currencyFormat(
                                                        Number(currentPeriodVolume),
                                                    )}  кВт*ч`,
                                                ),
                                            )
                                        }

                                        if (Number(value) <= 0) {
                                            return Promise.reject(new Error('Введите объем'))
                                        }

                                        return Promise.resolve()
                                    },
                                }),
                            ]}
                            label="Объем, кВт*ч"
                        >
                            <InputNumber
                                placeholder="Введите значение"
                                className={style.input}
                                value={volume}
                                onChange={(value) => setVolume(value)}
                                formatter={(value) =>
                                    `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
                                }
                                controls={false}
                            />
                        </Form.Item>
                        <Form.Item
                            name="account"
                            label="Лицевой счет"
                            className={style.dropdownWrapper}
                        >
                            <Input
                                defaultValue={`${currentAccount?.name} (№${currentAccount?.number})`}
                                className={style.input}
                                disabled
                            />
                        </Form.Item>
                        <Divider />
                        <div className={style.infoBlock}>
                            <div className={style.infoWrapper}>
                                <div className={style.info}>Объем</div>
                                <div className={style.itemValue}>
                                    {!volume ? '-' : `${currencyFormat(volume)} кВт*ч`}
                                </div>
                            </div>
                            <div className={style.infoWrapperSecond}>
                                <div className={style.info}>Оплата</div>
                                <div className={style.itemValue}>{currencyFormat(rate)}</div>
                            </div>
                        </div>
                    </Form>
                    <ButtonsBlock
                        margin
                        disableCondition={!volume}
                        confirmTitle="Погасить"
                        confirmAction={onSubmit}
                        cancelAction={goBack}
                    />
                </Card>
            </div>
        </LoadingContainer>
    )
}

export default OwnRepaymentGA
