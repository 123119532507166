import { FC, useState, useRef, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { Card, CenteredSpin, IFile } from '../ui'
import { Box } from '../ui/Box/Box'
import { useSelector } from 'react-redux'
import { ButtonsBlock } from '../Edit/components'
import { Divider, message } from 'antd'
import DocumentsBlock from '../AdminUploading/components/DocumentsBlock/DocumentsBlock'
import style from './style.m.less'
import { useTranslation, Trans } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import useRequest from '../hooks/useRequest'
import {sendRealization, getFromFile, sendGroupRealization} from './config'
import {ArrowLeft, FolderCross} from '../../assets/icons'
import { signBody, currencyFormat } from '../helpers'
import IAppState from '../../store/states'
import {getContracts} from "../UserGreenTools/config";
import useMount from "../hooks/useMount";
import {PaginationType} from "../Users/types";

interface IGroupRealizationAG {
    // isWithPayment?: boolean
}

const GroupRealizationAG: FC<IGroupRealizationAG> = (/*{ isWithPayment }*/) => {
    const {t} = useTranslation()
    const {fetch: fetchTotal, result: resultTotal, isLoading: isLoadingTotal} = useRequest(getContracts)
    const {state} = useLocation()
    const [accountId, setAccountID] = useState<string>(state?.accountId)
    const navigate = useNavigate()
    const [fileTotalCount, setFIleTotalCount] = useState(0)
    const [fileTotalVolume, setFIleTotalVolume] = useState(0)
    const [selectedRepayments, setSelectedRepayments] = useState<any>([{ user_id: '', volume: 0 }])
    const [files, setFiles] = useState<IFile[]>([])
    const [stFile, stSetFile] = useState<File>()
    const [confirmDisabled, setConfirmDisabled] = useState(true)

    const getDataListTotal = ({page = 1, pageSize = 100}: PaginationType) => {
        fetchTotal({
            body: {
                limit: pageSize,
                offset: (page - 1) * pageSize,
                filters: {
                    status_id: 9,
                    account_id: accountId
                },
            },
            errorCodeMessage: true,
        })
    }

    useMount(() => {
        getDataListTotal({page: 1, pageSize: 0})
    })

    const fileHandler = (list: any) => {
        setFiles(list)
    }

    const { fetch: fetchConsumers } = useRequest(getFromFile)

    useEffect(() => {
        if (files.length && resultTotal?.contracts) {
            const body = {
                document_id: files[0].id,
                account_id: accountId
            }
            fetchConsumers({
                body,
                onSuccess: (data) => {
                    setFIleTotalCount(data?.contracts_count)
                    setFIleTotalVolume(data?.consumed_total_volume)
                    setSelectedRepayments(data?.contract_consumers)
                    setConfirmDisabled(!(
                        resultTotal?.total
                        && data?.contracts_count === resultTotal?.total
                        && resultTotal?.totalVolumes
                        && data?.consumed_total_volume === resultTotal?.totalVolumes
                    ))
                },
                onError: (error) => {
                    setFIleTotalCount(0)
                    setFIleTotalVolume(0)
                    setConfirmDisabled(true)
                    message.error(error.err_messages[0])
                },
                errorCodeMessage: false,
                showError: false
            })
        }
    }, [files])

    const userInfo = useSelector((store: IAppState) => store.user)
    const {data} = userInfo

    const stateRef = useRef()
    stateRef.current = selectedRepayments

    const stFileFunction = (file: File) => {
        stSetFile(file)
    }

    const {fetch} = useRequest(sendGroupRealization)

    const getBack = () => {
        navigate(-1)
    }

    const confirmRepayment = async () => {
        setConfirmDisabled(true)
        const body = {
            contract_consumers: selectedRepayments,
        }
        const {request_id, signature} = await signBody(body, data)
        fetch({
            body,
            getParams: {
                request_id,
            },
            config: {
                headers: {
                    Signature: signature,
                },
            },
            successMessage: {
                title: t('detailedCard.success'),
                description: t('realizationAG.realizationSuccessMsg'),
            },
            onSuccess: () => navigate(-1),
            showError: true,
        })
    }

    if (!resultTotal || isLoadingTotal) {
        return <CenteredSpin />
    }

    return (
        <div className={style.container}>
            <div className={style.titleWrapper}>
                <span className={style.backLink} onClick={() => navigate(-1)}>
                    <ArrowLeft />
                </span>
                <div className={style.mainHeader}>
                    {t('realizationAG.groupMainTitle')}
                </div>
            </div>
            {resultTotal?.total > 0 && (
                <Card className={style.repaymentCard}>
                    <div className={style.title}>
                        {t('realizationAG.groupTitle')}
                    </div>
                    <DocumentsBlock
                        value={files}
                        onChange={fileHandler}
                        setFile={stFileFunction}
                        hideComment
                        serviceId={4}
                        description={t('realizationAG.uploadFile')}
                        customStyle={{width: '440px', marginTop: '24px'}}
                    />
                    <Divider/>
                    <div className={style.repaymentItemWrapper}>
                        <div className={style.repaymentItemName}>{t('realizationAG.volume')}</div>
                        <div className={style.repaymentItemValue}>
                            {!resultTotal?.totalVolumes ? '-' : `${currencyFormat(resultTotal?.totalVolumes)}`} {t('realizationAG.scale')}
                        </div>
                    </div>
                    <div className={style.repaymentItemWrapper}>
                        <div className={style.repaymentItemName}>{t('realizationAG.volumeSold')}</div>
                        <div className={style.repaymentItemValue}>
                            {!fileTotalVolume ? '-' : `${currencyFormat(fileTotalVolume)}`} {t('realizationAG.scale')}
                        </div>
                    </div>
                    <div className={style.repaymentItemWrapper}>
                        <div className={style.repaymentItemName}>{t('realizationAG.countContracts')}</div>
                        <div className={style.repaymentItemValue}>
                            {!resultTotal?.total ? '-' : `${currencyFormat(resultTotal?.total)}`}
                        </div>
                    </div>
                    <div className={style.repaymentItemWrapper}>
                        <div className={style.repaymentItemName}>{t('realizationAG.countContractsInFile')}</div>
                        <div className={style.repaymentItemValue}>
                            {!fileTotalCount ? '-' : `${currencyFormat(fileTotalCount)}`}
                        </div>
                    </div>
                    <Divider/>
                    <ButtonsBlock
                        confirmTitle={t('greenToolsModal.signAndImplement')}
                        confirmDisabled={confirmDisabled}
                        cancelAction={getBack}
                        confirmAction={confirmRepayment}
                    />
                </Card>
            )}
            {resultTotal?.total <= 0 && (
                <Card className={style.card}>
                    <Box>
                        <FolderCross/>
                        <div className={style.textAlert}>
                            <Trans i18nKey = "emptyAGForRealization">
                                У вас нет Зелёных договоров<br/> для указания потребителей
                            </Trans>
                        </div>
                    </Box>
                </Card>
            )}
        </div>
    )
}

export default GroupRealizationAG
