// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".alertWrapper_Jvnm0 {\n  display: flex;\n  flex-direction: column;\n  margin-top: 32px;\n  margin-bottom: 8px;\n}\n.controlsWrapper_pgw5g {\n  display: flex;\n}\n.lockIcon_SkUlg {\n  color: aqua;\n}\n.payButton_Y1bMy {\n  width: max-content;\n  margin-top: 8px;\n  color: #296dff;\n  font-weight: 600;\n  cursor: pointer;\n}\n.warningAlert_heybo {\n  border: 1px solid #fff1b8;\n  margin-top: 16px;\n  border-radius: 12px;\n  width: fit-content;\n  line-height: 22px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/DetailedCard/components/ControlsBlock/style.m.less"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,gBAAA;EACA,kBAAA;AACJ;AAEA;EACI,aAAA;AAAJ;AAGA;EACI,WAAA;AADJ;AAIA;EACI,kBAAA;EACA,eAAA;EACA,cAAA;EACA,gBAAA;EACA,eAAA;AAFJ;AAMA;EACI,yBAAA;EACA,gBAAA;EACA,mBAAA;EACA,kBAAA;EACA,iBAAA;AAJJ","sourcesContent":[".alertWrapper {\n    display: flex;\n    flex-direction: column;\n    margin-top: 32px;\n    margin-bottom: 8px;\n}\n\n.controlsWrapper {\n    display: flex;\n}\n\n.lockIcon {\n    color: aqua;\n}\n\n.payButton {\n    width: max-content;\n    margin-top: 8px;\n    color: #296dff;\n    font-weight: 600;\n    cursor: pointer;\n}\n\n\n.warningAlert {\n    border: 1px solid #fff1b8;\n    margin-top: 16px;\n    border-radius: 12px;\n    width: fit-content;\n    line-height: 22px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"alertWrapper": "alertWrapper_Jvnm0",
	"controlsWrapper": "controlsWrapper_pgw5g",
	"lockIcon": "lockIcon_SkUlg",
	"payButton": "payButton_Y1bMy",
	"warningAlert": "warningAlert_heybo"
};
export default ___CSS_LOADER_EXPORT___;
